@import 'antd/dist/antd.css';
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px rgb(0, 0, 0);
	border-radius: 3px;
}
::-webkit-scrollbar-thumb {
	background: #575757;
	border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
	background: #464646;
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
