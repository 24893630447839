@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'antd/dist/antd.min.css';
* {
	font-family: 'Inter', sans-serif;
}

body {
	margin: 0;
	font-family: 'Inter', sans-serif;
}
h1 {
	margin: 0;
	font-family: 'Inter', sans-serif;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none !important;
}
.color-title {
	color: red;
}
.ant-modal-close-x {
	color: red;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
	background-color: #13ae81;
	color: white;
}
.ant-switch-checked {
	background-color: #13ae81 !important;
}

.ant-pagination-item-active {
	border-color: #13ae81 !important;
	border-width: 2px !important;
}

.ant-pagination-item-active > a {
	color: #13ae81 !important;
}
.ant-select-selection-item {
	color: #13ae81 !important;
}
.ant-pagination-item:hover,
.ant-input:hover {
	border-color: #13ae81 !important;
}
.ant-pagination-item > a:hover {
	color: #13ae81 !important;
}
